<template>
  <div class="Dashboard">
    <div class="Group">
      <div class="Box">
        <span class="Title">{{ mixWB('GROUPS') }}</span>
        <span class="Number">{{ allStats.totalGroups }}</span>
      </div>
      <div class="Box">
        <span class="Title">{{ mixWB('USERS') }}</span>
        <span class="Number">{{ allStats.totalMembers }}</span>
      </div>
      <div class="Box">
        <span class="Title">{{ mixWB('LOGINS') }}</span>
        <span class="Number">{{ allStats.totalLogins }}</span>
      </div>
      <div class="Box">
        <span class="Title">{{ mixWB('COMMENTS') }}</span>
        <span class="Number">{{ allStats.totalComments }}</span>
      </div>
      <div class="Box">
        <span class="Title">{{ mixWB('FEEDBACK') }}</span>
        <span class="Number">{{ allStats.totalFeedback }}</span>
      </div>
      <div class="Box">
        <span class="Title">{{ mixWB('COMPLETED') }}</span>
        <span class="Number">{{ completed }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters([
      'allStats',
    ]),
    completed() {
      if (!this.allStats.usersCompletedOnce || !this.allStats.usersCompletedOnce.length) {
        return 0
      }

      return this.allStats.usersCompletedOnce.length
    },
  },
}
</script>

<style lang="stylus" scoped>
  .Dashboard
    display block

  .Group
    display flex
    gap 20px
    flex-wrap wrap

  .Box
    flex 1 0 32%
    flex-center-children-column()
    justify-content center
    gap 10px
    min-height 200px
    background-color #fff
    border 1px solid $color_grey_light
    .Title
      text-transform uppercase
      font-size 1.5rem
    .Number
      font-size 2.5rem
</style>
